<template>
  <Toast position="top-center" class="topContainer" severity="warning">
    <template #message="slotProps">
      <div class="flex flex-column align-items-start" style="flex: 1">
        <div class="flex align-items-center gap-2">
          <span class="font-bold text-900 flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <title>{{ $t("Snackbar.warning.title") }}</title>
              <g fill="#212121">
                <defs></defs>
                <path
                  id="1678822571986-47583_Warning_MIO_"
                  data-name="Warning [MIO] ***"
                  class="cls-1"
                  d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"
                  fill="#212121"
                ></path>
              </g>
            </svg>
            <span class="warning_title">{{ $t("Snackbar.warning.title") }}</span>
          </span>
        </div>
        <div class="messageContainer" ref="popupContainer">
          <div class="my-3 text-900 messageLeftBox" :aria-label="slotProps.message.summary">
            {{ text }}
          </div>
          <div class="messageRightBox">
            <Button @click="ok()">Ok</Button>
          </div>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script>
import { mapGetters } from "vuex";
import { localizationMixin } from "../mixins/localizationMixin";
import Toast from "primevue/toast";
export default {
  components: {
    Toast,
  },
  mixins: [localizationMixin],
  computed: {
    ...mapGetters(["getErrorMessage", "loginAttempts"]),

    loginTimeout() {
      var duration = new Date(Date.now()) - new Date(this.loginAttempts.timestamp);
      var seconds = Math.floor(duration / 1000);
      var timer = (this.loginAttempts.count / 3) * 120;
      if (
        this.loginAttempts.count >= 3 &&
        this.loginAttempts.count % 3 == 0 &&
        seconds >= 0 &&
        seconds <= timer
      ) {
        this.countDownTimer(timer - seconds);
        return (timer - seconds) * 1000;
      }
      return 0;
    },
  },
  data() {
    return {
      showAppSnackbar: true,
      text: "",
      countDown: 0,
      timeout: 0,
      loop: true,
      recreate: false,
      removeToastMessage: false,
    };
  },
  methods: {
    countDownTimer(value) {
      this.countDown = value;
      var seconds = Math.floor(value % 60);
      var minutes = Math.floor((value / 60) % 60);
      var hours = Math.floor((value / (60 * 60)) % 24);
      if (
        this.recreate &&
        (this.countDown === undefined || (this.countDown != undefined && this.countDown <= 0))
      ) {
        var message = `${this.getErrorMessage}. ${this.$t("Snackbar.error.timeout")} `;
        this.recreate = false;
      } else {
        message = ` ${this.$t("Snackbar.error.timeout")} `;
      }
      message += hours > 0 ? `${hours} ${this.$t("Snackbar.error.hours")} ` : "";
      message += minutes > 0 ? `${minutes} ${this.$t("Snackbar.error.minutes")} ` : "";
      message += seconds > 0 ? `${seconds} ${this.$t("Snackbar.error.seconds")}. ` : "";

      this.text = message;
      if (this.countDown != undefined && this.countDown > 0) {
        this.removeToastMessage = true;
        setTimeout(() => {
          this.countDownTimer();
          if (!this.loop) {
            this.loop = true;
          }
        }, 1000);
      }
    },
    ok() {
      if (this.countDown == 0) {
        this.$toast.removeAllGroups();
        this.recreate = true;
      } else {
        this.recreate = true;
      }
    },
  },
  watch: {
    getErrorMessage: {
      deep: true,
      immediate: true,
      handler: function (newval) {
        if (newval) {
          this.text = newval;
          this.timeout = 5000;
          this.showAppSnackbar = true;
          this.$toast.removeAllGroups();
          this.$toast.add({
            severity: "warn",
            summary: this.translate("Snackbar.warning.title", "Warning"),
            detail: this.text,
            closable: false,
          });
        }
      },
    },
    loginAttempts: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.count == 0) {
          this.ok();
        }
      },
    },
    loginTimeout: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value > 0) {
          this.timeout = value;
          this.showAppSnackbar = true;
          if (!this.loop || this.recreate) {
            this.$toast.removeAllGroups();
            this.$toast.add({
              severity: "warn",
              summary: this.translate("Snackbar.warning.title", "Warning"),
              detail: this.text,
              closable: false,
            });
            this.removeToastMessage = true;
          }
          const firstfocus = this.$refs.popupContainer.querySelectorAll("Button");
          firstfocus[0].focus();
        } else if (this.removeToastMessage) {
          this.removeToastMessage = false;
          this.$toast.removeAllGroups();
          this.recreate = true;
        }
      },
    },
    text: {
      immediate: true,
      deep: true,
      handler() {},
    },
  },
};
</script>

<style>
.topContainer {
  left: 50% !important;
  width: 30% !important;
  top: 0px !important;
}
.messageContainer {
  display: flex;
  flex-flow: row nowrap;
  width: 100% !important;
  align-items: center;
}
.messageLeftBox {
  font-size: 1rem;
  flex: 1 1 auto;
}
.messageRightBox {
  flex: 1 0 2rem;
  text-align: right;
}
.messageRightBox .p-button {
  background: #00486e !important;
  border-color: #00486e !important;
  border-radius: 0.25rem !important;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #fbf6dd !important;
  border: 2px solid #e8a700 !important;
  border-radius: 0.25rem;
  margin: 1rem 0 0;
  padding: 1rem;
}
.warning_title {
  font-size: 1.25rem;
}
</style>

<script>
// @ is an alias to /src
import LoginLinks from "@/components/LoginLinks.vue";
import LoginPopupLinks from "@/components/LoginPopupLinks.vue";
import { AUTH_REQUEST } from "../store/actions/auth";
import { SHOW_LOADER } from "../store/actions/common";
import { mapGetters, mapActions } from "vuex";
import { CONFIG } from "../config";
import { i18n } from "../i18n";
import { localizationMixin } from "../mixins/localizationMixin";
import { getDobYears } from "../utilities/util";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

export default {
  name: "Login",
  mixins: [localizationMixin],
  components: {
    LoginLinks,
    LoginPopupLinks,
    InputText,
    Dropdown,
    Button,
  },
  mounted: function () {
    this.getAccessCode();
  },
  computed: {
    ...mapGetters(["getClientConfig", "loginAttempts", "selectedLanguage"]),

    monthsArray: function () {
      return [
        { mtext: this.$t("January"), mval: "0" },
        { mtext: this.$t("February"), mval: "1" },
        { mtext: this.$t("March"), mval: "2" },
        { mtext: this.$t("April"), mval: "3" },
        { mtext: this.$t("May"), mval: "4" },
        { mtext: this.$t("June"), mval: "5" },
        { mtext: this.$t("July"), mval: "6" },
        { mtext: this.$t("August"), mval: "7" },
        { mtext: this.$t("September"), mval: "8" },
        { mtext: this.$t("October"), mval: "9" },
        { mtext: this.$t("November"), mval: "10" },
        { mtext: this.$t("December"), mval: "11" },
      ];
    },
    datevalid: function () {
      return Date.now();
    },
    termsofUse() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/Family+Portal+Terms+of+Use.pdf"
      );
    },
    loginTimeout() {
      var duration = new Date(Date.now()) - new Date(this.loginAttempts.timestamp);
      var seconds = Math.floor(duration / 1000);
      // if duration is gretaer than 4 hours clear login attempts count
      if (Math.floor(seconds / (60 * 60)) >= 4) {
        this.CLEAR_LOGIN_ATTEMPTS();
      }
      var waitTimeInSeconds = (this.loginAttempts.count / 3) * 120;
      if (
        this.loginAttempts.count >= 3 &&
        this.loginAttempts.count % 3 == 0 &&
        seconds >= 0 &&
        seconds <= waitTimeInSeconds
      ) {
        this.countDownTimer(waitTimeInSeconds - seconds);
        return (waitTimeInSeconds - seconds) * 1000;
      }
      return 0;
    },
    snackbar: {
      get() {
        return this.loginTimeout > 0;
      },
      set() {},
    },
    locale() {
      return i18n.global.locale;
    },
    headerFormat() {
      let header = this.$t("Login.info");
      return header.charAt(0).toUpperCase() + header.toLocaleLowerCase().slice(1);
    },
  },

  methods: {
    getAccessCode() {
      if (sessionStorage.getItem("fromaccess")) {
        this.accessCode = sessionStorage.getItem("accesscode");
      } else {
        this.accessCode = null;
      }
    },
    clearFormErrors() {
      this.errors = [];
      this.showErrorMessages = false;
    },
    formSubmit() {
      this.errors = [];
      this.notValid = false;
      if (!this.accessCode) this.notValid = true;

      if (this.accessCode && this.accessCode.length < 6) this.notValid = true;

      if (!this.monthSelected) this.notValid = true;

      if (!this.daySelected) this.notValid = true;

      if (!this.yearSelected) this.notValid = true;

      if (!this.fName) this.notValid = true;

      if (this.notValid) {
        this.showErrorMessages = true;
        return;
      } else {
        this.showErrorMessages = false;
      }

      sessionStorage.clear();
      var dob = new Date(this.yearSelected, this.monthSelected, this.daySelected);
      var accessCode = this.accessCode;
      var fname = this.fName;
      this.$store.dispatch(SHOW_LOADER);
      this.$store
        .dispatch(AUTH_REQUEST, {
          Name: fname,
          AccessCode: accessCode,
          DateOfBirth: dob,
          ClientName: this.getClientConfig.clientname,
          FetchHistoricalData: this.getClientConfig.fetchHistoricalData,
        })
        .then(() => {
          this.CLEAR_LOGIN_ATTEMPTS();
          this.$router.push("/home");
        })
        .catch((err) => {
          if (err.response !== undefined) {
            this.SET_LOGIN_ATTEMPTS();
          }
        });
    },
    openResource(resource) {
      window.open(resource, "_blank");
    },
    countDownTimer(value) {
      this.countDown = value;
      if (this.countDown != undefined && this.countDown > 0) {
        setTimeout(() => {
          this.countDownTimer();
        }, 1000);
      }
    },
    ...mapActions(["SET_LOGIN_ATTEMPTS", "CLEAR_LOGIN_ATTEMPTS"]),
  },

  data: () => ({
    daysArray: Array(31)
      .fill(1)
      .map((e, i) => i + 1),
    yearsArray: getDobYears(),
    monthSelected: null,
    daySelected: null,
    yearSelected: null,
    accessCode: null,
    fName: null,
    isValid: false,
    errors: [],
    showErrorMessages: false,
    countDown: 0,
  }),
  watch: {
    loginAttempts: {
      immediate: true,
      deep: true,
      handler() {},
    },
    loginTimeout: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value > 0) {
          this.monthSelected = null;
          this.daySelected = null;
          this.yearSelected = null;
          this.accessCode = null;
          this.fName = null;
        }
      },
    },
    locale: {
      immediate: true,
      deep: true,
      handler() {
        this.errors = [];
        this.showErrorMessages = false;
      },
    },
  },
};
</script>

<template>
  <!-- <div class="grid" justify="center"> -->
  <div id="mainWrapper" aria-hidden="false" :class="getClientConfig.clientname">
    <div id="login_info">
      <form ref="form" class="login_form" novalidate="true">
        <fieldset>
          <legend class="pt-3">{{ headerFormat }}</legend>

          <span class="login_note" v-if="$t('Login.note')">
            {{ $t("Login.note") }}
          </span>

          <!-- Access code -->
          <div class="loginFieldContainer flex pb-2">
            <label class="labelcontainer" id="accessCodeLabel" for="access_code">
              <template v-if="getClientConfig.clientname === 'texas'">
                {{ translate("Login.Tx_access_code") }} ({{
                  translate("Login.Tx_access_code_trans")
                }}):</template
              >
              <template v-else>{{ translate("Login.access_code") }} </template>
            </label>
            <div class="col-6 p-0">
              <InputText
                class="darker-placeholder"
                style="width: 100%"
                :disabled="snackbar"
                v-model="accessCode"
                size="small"
                id="access_code"
                :placeholder="translate('Login.access_code_aria_label')"
                aria-required="required"
                aria-labelledby="accessCodeLabel"
                aria-describedby="uniqueStudentAccessCodeError"
              />
              <span
                class="error"
                role="alert"
                id="uniqueStudentAccessCodeError"
                v-if="showErrorMessages && accessCode == null"
                :aria-label="translate('Login.error.access_code')"
                >{{ translate("Login.error") + " " + translate("Login.error.access_code") }}</span
              >
              <span
                class="error"
                role="alert"
                id="uniqueStudentAccessCodeError"
                v-else-if="showErrorMessages && accessCode.length < 6"
                :aria-label="translate('Login.error.access_code_length')"
                >{{
                  translate("Login.error") + " " + translate("Login.error.access_code_length")
                }}</span
              >
            </div>
          </div>

          <!-- DOB section -->
          <div class="loginFieldContainer flex pb-2">
            <label class="labelcontainer" id="dobLabel">{{ translate("Login.dobLabel") }}</label>
            <div class="flex gap-2 loginDateContainer">
              <!--Birth Month -->
              <div class="selectContainer">
                <label class="labelcontainer" id="dobMonthLabel" for="dobMonth">{{
                  translate("Login.dobMonthLabel")
                }}</label>
                <Dropdown
                  class="darker-placeholder"
                  id="dobMonth"
                  :disabled="snackbar"
                  v-model="monthSelected"
                  :options="monthsArray"
                  optionLabel="mtext"
                  optionValue="mval"
                  :placeholder="translate('Login.dob_month_aria_label')"
                  aria-required="required"
                  role="combobox"
                  aria-labelledby="dobMonthLabel dobMonthError"
                  aria-expanded="false"
                  style="width: 100%"
                  appendTo="self"
                />
                <span
                  class="error"
                  role="alert"
                  id="dobMonthError"
                  aria-live="assertive"
                  v-if="showErrorMessages && monthSelected == null"
                  :aria-label="translate('Login.error.month')"
                  >{{ translate("Login.error") + " " + translate("Login.error.month") }}</span
                >
              </div>
              <!--Birth Day -->
              <div class="selectContainer">
                <label class="labelcontainer" id="dobDayLabel" for="dobDay">{{
                  translate("Login.dobDayLabel")
                }}</label>
                <Dropdown
                  class="darker-placeholder"
                  id="dobDay"
                  :disabled="snackbar"
                  v-model="daySelected"
                  :options="daysArray"
                  :placeholder="translate('Login.dob_day_aria_label')"
                  aria-required="required"
                  :aria-label="translate('Login.dobDayLabel')"
                  aria-labelledby="dobDayLabel dobDayError"
                  aria-expanded="false"
                  role="combobox"
                  style="width: 100%"
                  appendTo="self"
                />
                <span
                  class="error"
                  role="alert"
                  id="dobDayError"
                  aria-live="assertive"
                  v-if="showErrorMessages && daySelected == null"
                  :aria-label="translate('Login.error.day')"
                  >{{ translate("Login.error") + " " + translate("Login.error.day") }}</span
                >
              </div>

              <!--Birth Year -->
              <div class="selectContainer">
                <label class="labelcontainer" id="dobYearLabel" for="dobYear">{{
                  translate("Login.dobYearLabel")
                }}</label>
                <Dropdown
                  class="darker-placeholder"
                  id="dobYear"
                  :disabled="snackbar"
                  v-model="yearSelected"
                  :options="yearsArray"
                  :placeholder="translate('Login.dob_year_aria_label')"
                  aria-required="required"
                  :aria-label="translate('Login.dobYearLabel')"
                  aria-labelledby="dobYearLabel dobYearError"
                  role="combobox"
                  style="width: 100%"
                  appendTo="self"
                />
                <span
                  class="error"
                  role="alert"
                  id="dobYearError"
                  aria-live="assertive"
                  v-if="showErrorMessages && yearSelected == null"
                  :aria-label="translate('Login.error.year')"
                  >{{ translate("Login.error") + " " + translate("Login.error.year") }}</span
                >
              </div>
            </div>
          </div>

          <!-- First name -->
          <div class="loginFieldContainer flex">
            <label class="labelcontainer" id="loginFN_label">
              <template v-if="getClientConfig.clientname === 'texas'">
                {{ translate("Login.Tx_loginFN_label") }}
              </template>
              <template v-else>
                {{ translate("Login.loginFN_label") }}
              </template>
            </label>
            <div class="col-8 p-0">
              <InputText
                class="darker-placeholder"
                style="width: 100%"
                :disabled="snackbar"
                v-model="fName"
                size="small"
                id="first_name"
                :placeholder="
                  getClientConfig.clientname === 'texas'
                    ? translate('AccessCode.firstname_aria_label')
                    : translate('Login.firstname_aria_label')
                "
                aria-required="required"
                aria-labelledby="loginFN_label"
                aria-describedby="fNameError"
              />
              <span
                class="error"
                role="alert"
                id="fNameError"
                v-if="showErrorMessages && fName == null"
                :aria-label="translate('Login.error.fname')"
                >{{ translate("Login.error") + " " + translate("Login.error.fname") }}</span
              >
            </div>
          </div>

          <!-- Terms of Use -->
          <div v-if="getClientConfig.clientname != 'texas'" class="pt-2">
            <p>
              {{ translate("Login.termsofUse") }}
              <a href="#" @click="openResource(termsofUse)"> {{ translate("App.TOU") }} </a>.
              <span class="sr-only">{{ $t("LoginLinks.newwindow") }}</span>
            </p>
          </div>

          <Button
            id="btn_sign_in"
            :disabled="snackbar"
            :label="
              getClientConfig.clientname === 'texas'
                ? translate('Login.Signon')
                : translate('Login.Signin')
            "
            @click="formSubmit"
          />
        </fieldset>
      </form>
    </div>

    <LoginPopupLinks v-if="getClientConfig.clientname === 'texas'" />

    <LoginLinks v-else />
  </div>
  <!-- </div> -->
</template>

<style>
.darker-placeholder .p-placeholder {
  color: #605e5c !important;
}
</style>
<style scoped>
.darker-placeholder::placeholder {
  color: #605e5c !important;
}

.main_wrapper .grid {
  margin-left: 0;
  margin-right: 0;
}
.outerWrapper {
  padding: 0;
}
#mainWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  max-width: 900px;
  margin: auto;
  /* width: 90%; */
  /* font-size: 95%; */
  padding: 0 1.25rem;
  position: relative;
}
.texas #mainWrapper {
  max-width: 552px;
  padding: 0;
  width: 100%;
}

/* Header */
.CAIHeader {
  align-items: flex-end;
  color: #005e9e;
  display: flex;
  justify-content: space-between;
}
.CAIHeader .branding {
  display: flex;
  align-items: center;
}
.CAIHeader .logo {
  background: url("../assets/images/calogo.svg") no-repeat;
  display: inline-block;
  height: 35px;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  width: 115px;
}
.CAIHeader .logo:hover {
  color: inherit;
  text-decoration: none;
}
.CAIHeader .logo.markonly {
  background: url("../assets/images/calogo.svg") no-repeat;
  padding: 0.5em 0 0 50px;
}
.CAIText {
  font-size: 0.688em; /*11px*/
  font-weight: 400;
  line-height: 10px;
  text-align: center;
}
.CAIText span {
  color: #2c9ed9;
}
/* .CAIHeader .systemName {
  border-left: 1px solid #005e9e;
  font-size: 2.125em;
  font-weight: 300;
  margin: 0 0 0 0.5em;
  padding: 0 0 0 0.5em;
} */
.CAIHeader .userbar {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  line-height: 18px;
  text-align: right;
}

.florida .CAIHeader .CAIText {
  display: none;
}
.florida .CAIHeader .logo {
  background: url("../assets/images/florida_logo.svg") no-repeat;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 151px;
}
.alertContent {
  width: 350px;
  margin: 0 auto;
  text-align: left;
}
.branding {
  border: 1px solid #ccc;
  border-bottom-color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 1em 1em 0;
  z-index: 1;
}

@media print {
  .CAIHeader .logo.markonly {
    content: url("../assets/images/CA-Logo_CLR_Horz.svg");
  }
  .CAIHeader .userbar {
    display: none;
  }

  .florida .CAIHeader .CAIText {
    display: none;
  }
  .florida .CAIHeader .logo {
    content: url("../assets/images/florida_logo.svg");
  }
}

/*Login*/
.loginDateContainer {
  flex: 1 0 auto;
  /* width: 100%; */
}
.loginDateContainer .selectContainer {
  width: 20%;
}
.loginDateContainer .selectContainer:first-child {
  width: 40%;
}
.loginDateContainer .selectContainer:last-child {
  width: 25%;
}
.texas .loginDateContainer .selectContainer {
  width: 33.3333%;
}
.loginDateContainer label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#btn_sign_in {
  /* height: 36px; */
  /* width: 96px; */
  padding: 0.5rem 1rem;
  margin: 1.5rem auto;
  text-transform: uppercase;
  align-items: center;
  color: #fff;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  background-color: #1976d2 !important;
  border-radius: 0.25rem;
}
.texas #btn_sign_in {
  background-color: #00486e !important;
  /* width: initial; */
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.texas #btn_sign_in:hover {
  background-color: #27587b !important;
  border: 1px solid #27587b !important;
}
#btn_sign_in:hover {
  background-color: #6598dd !important;
  border: 1px solid #6598dd !important;
}

#btn_sign_in:deep(span) {
  letter-spacing: 0.0892857143em;
  font-weight: 500;
  font-size: 1rem;
}
.loginFieldContainer {
  padding: 1.5rem 0;
}
.texas .loginFieldContainer {
  padding: 0;
}
.texas .loginFieldContainer:deep(input),
.texas .loginFieldContainer:deep(.p-dropdown) {
  border-radius: 4px;
}
.loginFieldContainer:deep(input),
.loginFieldContainer:deep(.p-dropdown) {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid gray;
}
.loginFieldContainer .labelcontainer {
  color: #000;
}
.loginFieldContainer:deep(input::placeholder) {
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.4);
}

.texas .login_form .labelcontainer:first-child {
  width: 100%;
}
.texas .login_form .labelcontainer {
  color: #fff;
}
.texas .loginFieldContainer {
  flex-direction: column;
}
.texas .col-6,
.texas .col-8 {
  width: 100%;
}

#login_info {
  border: 1px solid #ccc;
  border-radius: 0 10px 0 0;
  /* margin-top: -1px; */
  padding: 1rem;
}
.texas #login_info {
  background: #367aab;
  border: none;
  border-radius: 0;
  color: #fff;
  margin-top: 0;
  padding: 0;
}
#login_info .login_form {
  margin: 0 auto;
  padding: 0 1rem;
  width: 70%;
}
.texas #login_info .login_form {
  width: 100%;
}
fieldset {
  display: flex;
  flex-flow: column nowrap;
}
.login_form legend {
  color: #000;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  text-align: left;
}
.texas .login_form legend {
  display: none;
}
.login_form .login_note {
  font-size: 0.9rem;
  margin: 0 0 0.5rem;
  text-align: left;
}
.texas .login_form .login_note {
  font-size: 0.75rem;
  font-weight: 200;
  /* margin: 1em 0; */
}
.login_form p {
  margin: 0;
  text-align: left;
}
.v-text-field {
  margin-top: 0;
  padding-top: 0;
}

.labelcontainer {
  font-weight: bold;
  padding: 0.5rem 0 0;
  text-align: left;
  flex: 0 0 25%;
}
.texas .labelcontainer {
  flex: 1 1 100%;
  font-weight: normal;
  margin: 0 0 0.25rem;
  max-width: 100%;
}
.fieldcontainer {
  padding-top: 0;
}
.texas .fieldcontainer {
  flex: 1 1 100%;
  max-width: 100%;
  padding: 0;
}
.texas .fieldcontainer.v-col-2,
.texas .fieldcontainer.v-col-3,
.texas .fieldcontainer.v-col-4 {
  flex: 1 1 calc(33% - 0.25rem);
  margin-right: 0.5rem;
  max-width: calc(33% - 0.25rem);
}
.texas .fieldcontainer.v-col-3 {
  margin-right: 0;
}

.fieldcontainer .v-input--is-focused label {
  top: 2px;
}

.texas .v-text-field input {
  background-color: #fff !important;
  border-radius: 4px !important;
}
.texas .theme--light.v-input input,
.texas .theme--light.v-input textarea {
  background: #fff;
}

footer {
  border-top: none;
  margin: 0;
}
.texas .loginpage.v-application .error--text,
.texas .loginpage .v-btn--outlined .v-btn__content .v-icon,
.texas .loginpage .v-btn--round .v-btn__content .v-icon {
  color: #fff !important;
  caret-color: #fff !important;
}

.error {
  color: #e61c09;
  display: block;
  font-weight: 700;
  position: relative;
  top: 0.25rem;
}
.texas .error {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.2rem;
  padding: 0.1rem 0.25rem;
}

@media (max-width: 500px) {
  #mainWrapper {
    padding: 0;
    width: 100%;
  }

  #login_info {
    border-radius: 0;
    padding: 0;
  }
  .texas #login_info {
    padding-top: 0.5rem;
  }
  #login_info .login_form {
    margin: 0;
    width: 100%;
  }

  /* #login_info .row {
    flex-flow: column nowrap;
  }
  .texas #login_info .row {
    flex-flow: row wrap;
  } */
  #login_info .v-row .labelcontainer {
    flex: 100% !important;
    max-width: 100% !important;
  }
  #login_info .v-row .fieldcontainer.v-col.v-col-6,
  #login_info .v-row .fieldcontainer.v-col.v-col-7 {
    flex: 100%;
    max-width: 100%;
    padding: 0;
  }
  #login_info .v-row .fieldcontainer.v-col.v-col-4,
  #login_info .v-row .fieldcontainer.v-col.v-col-2,
  #login_info .v-row .fieldcontainer.v-col.v-col-3 {
    padding: 0 1.5rem 0 0;
    flex: 1 1 calc(33% - 0.5rem);
    max-width: calc(33% - 0.25rem);
  }
  .texas #login_info .v-row .fieldcontainer.v-col-2,
  .texas #login_info .v-row .fieldcontainer.v-col-3,
  .texas #login_info .v-row .fieldcontainer.v-col-4 {
    padding: 0;
  }
  .alertContent {
    width: 100%;
  }
  .col-6,
  .col-8,
  .w-2,
  .w-3,
  .w-4,
  .texas .loginDateContainer .w-2,
  .texas .loginDateContainer .w-3,
  .texas .loginDateContainer .w-4,
  .selectContainer {
    padding-bottom: 0.5rem;
    width: 100% !important;
  }
  .loginFieldContainer {
    padding: 0.5rem 0;
  }
  .loginDateContainer {
    flex-flow: column nowrap;
  }
  .labelcontainer {
    padding: 0.25rem 0;
  }
  #btn_sign_in {
    height: inherit;
    padding: 1rem;
    width: 100%;
  }
  #login_help {
    flex-flow: column nowrap;
  }
}

@media (max-width: 470px) {
  #login_info fieldset {
    min-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #mainWrapper {
    padding: 0;
  }
  #login_info {
    border-radius: 10px 10px 0 0;
  }
  #login_info .login_form {
    width: 100%;
  }
  .login_form .loginFieldContainer {
    flex-direction: column;
  }
  .login_form .labelcontainer.col-3 {
    width: 100%;
  }
  /* #login_help {
    flex-flow: column nowrap;
  } */
}

@media only screen and (max-width: 1023px) {
  #login_info .login_form {
    width: 100%;
  }
}

/* @media (min-width: 901px) and (max-width: 1024px) {
  #login_info .login_form {
    width: 70%;
  }
}
@media (min-width: 421px) and (max-width: 900px) {
  #login_info .login_form {
    width: 90%;
  }
} */

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
</style>
